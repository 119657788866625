import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config'; // Importa la configuración
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/InvoiceList.css';
import flechaAtras from '../images/flecha-atras.png';
import '../styles/Devoluciones.css';

// Configuración de la instancia de axios con la URL base desde config
const api = axios.create({
    baseURL: config.apiUrl,
});

const InvoiceList = () => {
    const [invoices, setInvoices] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            const response = await api.get('/invoices'); // Usa la instancia configurada
            const sortedInvoices = response.data.sort((a, b) => new Date(a.mtime) - new Date(b.mtime));
            setInvoices(sortedInvoices);
        } catch (error) {
            console.error('Error fetching invoices:', error);
        }
    };

    const handleDeleteAll = async () => {
        if (password === 'agdistribucion9810') {
            if (window.confirm('¿Estás seguro de que quieres borrar todas las facturas?')) {
                try {
                    await api.delete('/invoices'); // Usa la instancia configurada
                    setInvoices([]);
                    setShowPasswordModal(false);
                    setPassword('');
                    setError('');
                } catch (error) {
                    console.error('Error deleting all invoices:', error);
                }
            }
        } else {
            setError('Clave incorrecta. Inténtalo de nuevo.');
        }
    };

    const filterInvoicesByDate = () => {
        if (!selectedDate) return [];
        const selectedDateString = selectedDate.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).replace(/\//g, '-'); // Formato DD-MM-YYYY

        return invoices.filter(invoice => invoice.name.includes(selectedDateString));
    };

    return (
        <div className="container1">
            <div className="flecha">
                <a href="/">
                    <img src={flechaAtras} alt="Ir a la página principal" />
                </a>
            </div>
            <h1 className="heading" style={{color : 'black'}}>Listado de Facturas</h1>
            <button onClick={() => setShowPasswordModal(true)} className="delete-button">Borrar todas las facturas</button>
            <div className="calendar">
                <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Seleccione una fecha"
                    customInput={<CustomInput />}
                />
            </div>
            <div className="grid">
                {selectedDate && filterInvoicesByDate().length > 0 ? (
                    filterInvoicesByDate().map((invoice, index) => (
                        <div key={index} className="grid-item">
                            <a href={`${config.apiUrl}/invoices/files/${invoice.name}`} target="_blank" rel="noopener noreferrer" className="link">
                                {invoice.name}
                            </a>
                        </div>
                    ))
                ) : (
                    <p className='pofa'>Por favor, seleccione una fecha para mostrar las facturas.</p>
                )}
            </div>
    
            {showPasswordModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className='modalh2'>Confirmar eliminación</h2>
                        <p className='modalp'>Ingrese la clave para borrar todas las facturas:</p>
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <button onClick={handleDeleteAll} className="confirm-button">Confirmar</button>
                        <button onClick={() => setShowPasswordModal(false)} className="cancel-button">Cancelar</button>
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            )}
        </div>
    );
    
};

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input" onClick={onClick} ref={ref}>
        {value || 'Seleccione una fecha'}
    </button>
));

export default InvoiceList;








