import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config'; // Importa la configuración
import '../styles/Facturacion.css';
import flechaAtras from '../images/flecha-atras.png';

// Configuración de la instancia de axios con la URL base desde config
const api = axios.create({
    baseURL: config.apiUrl,
});

const Facturacion = () => {
    const [productos, setProductos] = useState([]);
    const [factura, setFactura] = useState({
        cliente_nombre: '',
        cliente_direccion: '',
        empresa_nombre: '',
        productos: [],
        esDevolucion: false
    });
    const [pdfPath, setPdfPath] = useState('');

    useEffect(() => {
        api.get('/products') // Usa la instancia configurada
            .then(response => {
                const productosOrdenados = response.data.sort((a, b) => a.nombre.localeCompare(b.nombre));
                setProductos(productosOrdenados);
            })
            .catch(error => console.error('Error al cargar los productos:', error));
    }, []);

    const agregarProducto = (producto) => {
        if (producto) {
            setFactura({
                ...factura,
                productos: [...factura.productos, { ...producto, cantidad: 1, precio: parseFloat(producto.precio) }]
            });
        }
    };

    const eliminarProducto = (index) => {
        setFactura({
            ...factura,
            productos: factura.productos.filter((_, i) => i !== index)
        });
    };

    const manejarCambio = (e) => {
        const { name, value, type, checked } = e.target;
        setFactura({
            ...factura,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const manejarSubmit = (e) => {
        e.preventDefault();
    
        if (!factura.cliente_nombre || !factura.cliente_direccion || !factura.empresa_nombre || factura.productos.length === 0) {
            alert('Por favor, complete todos los campos y agregue al menos un producto.');
            return;
        }
    
        for (let producto of factura.productos) {
            if (typeof producto.precio !== 'number' || isNaN(producto.precio)) {
                console.error('Producto con precio inválido:', producto);
                return;
            }
        }
    
        api.post('/invoices', factura, { // Usa la instancia configurada
            headers: {
                'Content-Type': 'application/json'
            },
            maxRedirects: 0,  // Evita redirecciones si hay
            timeout: 60000    // Aumenta el tiempo de espera (60 segundos)
        })
        .then(response => {
            console.log('Factura guardada:', response.data);
            let pdfUrl = response.data.pdfPath;
            if (!pdfUrl.startsWith('http')) {
                pdfUrl = `${config.apiUrl.replace(/\/api$/, '')}${pdfUrl}`;
            }
            setPdfPath(pdfUrl);
    
            if (factura.esDevolucion) {
                api.post('/devoluciones', factura) // Usa la instancia configurada
                    .then(response => {
                        console.log('Devolución guardada:', response.data);
                    })
                    .catch(error => console.error('Error al guardar la devolución:', error));
            }
    
            setFactura({
                cliente_nombre: '',
                cliente_direccion: '',
                empresa_nombre: '',
                productos: [],
                esDevolucion: false,
            });
        })
        .catch(error => console.error('Error al guardar la factura:', error));
    };

    const calcularTotal = () => {
        return factura.productos.reduce((total, producto) => total + (producto.precio * producto.cantidad), 0);
    };

    const handleDownloadClick = () => {
        setPdfPath('');
    };

    const incrementarCantidad = (index) => {
        setFactura({
            ...factura,
            productos: factura.productos.map((producto, i) =>
                i === index ? { ...producto, cantidad: producto.cantidad + 1 } : producto
            )
        });
    };

    const decrementarCantidad = (index) => {
        setFactura({
            ...factura,
            productos: factura.productos.map((producto, i) =>
                i === index ? { ...producto, cantidad: producto.cantidad - 1 } : producto
            )
        });
    };

    const isMobile = window.innerWidth <= 600;

    return (
        <form className='okiii' onSubmit={manejarSubmit}>
            <div className="flecha">
                <a href="/">
                    <img src={flechaAtras} alt="Ir a la página principal" />
                </a>
            </div>
            <h2 style={{ color: 'black' }}>Realización de Pedidos</h2>
            <div>
                <label>Nombre del Cliente:</label>
                <input type="text" name="cliente_nombre" value={factura.cliente_nombre} onChange={manejarCambio} />
            </div>
            <div>
                <label>Dirección del Cliente:</label>
                <input type="text" name="cliente_direccion" value={factura.cliente_direccion} onChange={manejarCambio} />
            </div>
            <div>
                <label>Nombre de la Empresa:</label>
                <input type="text" name="empresa_nombre" value={factura.empresa_nombre} onChange={manejarCambio} />
            </div>
            <div>
                <label>Productos:</label>
                <select onChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                        agregarProducto(JSON.parse(value));
                    }
                }}>
                    <option value="">Selecciona un producto</option>
                    {productos.map(producto => (
                        <option key={producto.id} value={JSON.stringify(producto)}>
                            {producto.nombre}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                {factura.productos.map((producto, index) => (
                    <div key={index} className="producto-item">
                        <span>{producto.nombre} - {producto.precio}</span>
                        <div className="cantidad-control">
                            {isMobile && (
                                <>
                                    <button type="button" onClick={() => decrementarCantidad(index)}>-</button>
                                    <input
                                        type="number"
                                        value={producto.cantidad}
                                        onChange={(e) => {
                                            const nuevaCantidad = parseInt(e.target.value);
                                            setFactura({
                                                ...factura,
                                                productos: factura.productos.map((p, i) => i === index ? { ...p, cantidad: nuevaCantidad } : p)
                                            });
                                        }}
                                    />
                                    <button type="button" onClick={() => incrementarCantidad(index)}>+</button>
                                </>
                            )}
                            {!isMobile && (
                                <input
                                    type="number"
                                    value={producto.cantidad}
                                    onChange={(e) => {
                                        const nuevaCantidad = parseInt(e.target.value);
                                        setFactura({
                                            ...factura,
                                            productos: factura.productos.map((p, i) => i === index ? { ...p, cantidad: nuevaCantidad } : p)
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <button type="button" onClick={() => eliminarProducto(index)}>Eliminar</button>
                    </div>
                ))}
            </div>
           
            <div>
                <h3>Total: {calcularTotal()}</h3>
            </div>
            <button type="submit">Guardar Factura</button>

            {pdfPath && (
                <div className="download-container">
                    <a href={pdfPath} download={`invoice-${factura.cliente_nombre}.pdf`} target="_blank" rel="noopener noreferrer" className="download-link" onClick={handleDownloadClick}>Descargar Factura</a>
                </div>
            )}
        </form>
    );
};

export default Facturacion;














