import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config'; // Importa la configuración
import '../styles/Devoluciones.css';
import flechaAtras from '../images/flecha-atras.png';

// Configuración de la instancia de axios con la URL base desde config
const api = axios.create({
    baseURL: config.apiUrl,
});

const Devoluciones = () => {
    const [devoluciones, setDevoluciones] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        if (selectedDate) {
            api.get(`/invoices/devoluciones?date=${selectedDate}`) // Usa la instancia configurada
                .then(response => {
                    console.log('Devoluciones response:', response.data);
                    setDevoluciones(response.data);
                })
                .catch(error => {
                    console.error('Error fetching devoluciones:', error);
                });
        }
    }, [selectedDate]);

    const calcularTotalDevolucion = (devoluciones) => {
        return devoluciones.reduce((total, devolucion) => total + (devolucion.precio * devolucion.cantidad), 0);
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    return (
        <div className="containerDevoluciones">
            <div className="flecha">
                <a href="/">
                    <img src={flechaAtras} alt="Ir a la página principal" />
                </a>
            </div>
            <h1 className="h1Devoluciones">Devoluciones</h1>
            <div className="calendar5">
                <input 
                    type="date" 
                    value={selectedDate} 
                    onChange={handleDateChange} 
                    className="react-datepicker__input-container input"
                />
            </div>
            {selectedDate && (
                <div className="tableContainerDevoluciones">
                    {devoluciones.length > 0 ? (
                        devoluciones.map((devolucion, index) => (
                            <div key={index} className="cardDevoluciones">
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">ID Factura:</span>
                                    <span className="cardValueDevoluciones">{devolucion.factura_id}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Cliente:</span>
                                    <span className="cardValueDevoluciones">{devolucion.cliente_nombre}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Dirección:</span>
                                    <span className="cardValueDevoluciones">{devolucion.cliente_direccion}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Producto:</span>
                                    <span className="cardValueDevoluciones">{devolucion.producto_nombre}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Cantidad:</span>
                                    <span className="cardValueDevoluciones">{devolucion.cantidad}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Precio:</span>
                                    <span className="cardValueDevoluciones">{devolucion.precio}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Total:</span>
                                    <span className="cardValueDevoluciones">{(devolucion.precio * devolucion.cantidad).toFixed(2)}</span>
                                </div>
                                <div className="cardRowDevoluciones">
                                    <span className="cardLabelDevoluciones">Fecha:</span>
                                    <span className="cardValueDevoluciones">{new Date(devolucion.fecha).toLocaleDateString()}</span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="noDevolucionesDevoluciones">
                            No hay devoluciones para la fecha seleccionada
                        </div>
                    )}
                </div>
            )}
            <div className="footerDevoluciones">
                <h2 className="h2Devoluciones">Total Devoluciones: {calcularTotalDevolucion(devoluciones).toFixed(2)}</h2>
                <p className="pDevoluciones">© 2024 Distribución AG. Todos los derechos reservados.</p>
            </div>
        </div>
    );
};

export default Devoluciones;


















