import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';

import gestionImg from '../images/principal-gestion.png';
import pedidosImg from '../images/principal-pedidos.png';
import reportesImg from '../images/principal-reportes.png';
import devolucionImg from '../images/principal-devolucion.png';
import informeImg from '../images/principal-informe.png';

const HomePage = () => {
  return (
    <div className="homepage">
      <Link to="/gestion-productos" className="homepage__box gestion">
        <h2 className="homepage__box-title">Gestión de Productos</h2>
        <div className="homepage__box-image-container">
          <img src={gestionImg} alt="Gestión de Productos" className="homepage__box-image" />
        </div>
      </Link>

      <Link to="/create-invoice" className="homepage__box pedidos">
        <h2 className="homepage__box-title">Realización de Pedidos</h2>
        <div className="homepage__box-image-container">
          <img src={pedidosImg} alt="Realización de Pedidos" className="homepage__box-image" />
        </div>
      </Link>

      <Link to="/invoices" className="homepage__box reportes">
        <h2 className="homepage__box-title">Listado de Facturas</h2>
        <div className="homepage__box-image-container">
          <img src={reportesImg} alt="Listado de Facturas" className="homepage__box-image" />
        </div>
      </Link>

      <Link to="/devoluciones" className="homepage__box devoluciones">
        <h2 className="homepage__box-title">Devoluciones</h2>
        <div className="homepage__box-image-container">
          <img src={devolucionImg} alt="Devoluciones" className="homepage__box-image" />
        </div>
      </Link>

      <Link to="/daily-report" className="homepage__box informe">
        <h2 className="homepage__box-title">Informe Diario</h2>
        <div className="homepage__box-image-container">
          <img src={informeImg} alt="Informe Diario" className="homepage__box-image" />
        </div>
      </Link>
    </div>
  );
};

export default HomePage;




