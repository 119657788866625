import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './components/ProductList';
import Facturacion from './components/Facturacion';
import HomePage from './components/HomePage';
import InvoiceList from './components/InvoiceList';
import './App.css';
import DailyReport from './components/DailyReport';
import Devoluciones from './components/Devoluciones';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/gestion-productos" element={<ProductList />} />
          <Route path="/create-invoice" element={<Facturacion />} />
          <Route path="/invoices" element={<InvoiceList />} />
          <Route path="/devoluciones" element={<Devoluciones />} />
          <Route path="/daily-report" element={<DailyReport />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
