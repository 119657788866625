import React, { useState, useEffect } from 'react';
import config from '../config';
import axios from 'axios';
import '../styles/ProductList.css';
import flechaAtras from '../images/flecha-atras.png';

// Configuración de la instancia de axios con la URL base desde config
const api = axios.create({
    baseURL: config.apiUrl,
});

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({ nombre: '', cantidad: '', precio: '' });
    const [busqueda, setBusqueda] = useState('');
    const [editingProduct, setEditingProduct] = useState(null); // Estado para el producto que se está editando

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await api.get('/products');
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (editingProduct) {
            setEditingProduct({ ...editingProduct, [name]: value });
        } else {
            setNewProduct({ ...newProduct, [name]: value });
        }
    };

    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            await api.post('/products', newProduct);
            fetchProducts();
            setNewProduct({ nombre: '', cantidad: '', precio: '' });
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };

    const handleEditProduct = (product) => {
        setEditingProduct(product);
    };

    const handleUpdateProduct = async (e) => {
        e.preventDefault();
        try {
            await api.put(`/products/${editingProduct.id}`, editingProduct);
            fetchProducts();
            setEditingProduct(null); // Resetear el estado de edición
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    const handleDeleteProduct = async (id) => {
        try {
            await api.delete(`/products/${id}`);
            fetchProducts();
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    const productosFiltrados = products.filter(product =>
        product.nombre.toLowerCase().includes(busqueda.toLowerCase())
    );

    return (
        <div className="gestion-productos">
            <div className="flecha">
                <a href="/">
                    <img src={flechaAtras} alt="Ir a la página principal" />
                </a>
            </div>

            <h2 style={{color: 'black'}}>Gestión de Productos</h2>
            <div className="contenedor">
                <div className="centrado">
                    <div className="contenido">
                        <input
                            type="text"
                            name="nombre"
                            placeholder="Nombre del producto"
                            value={editingProduct ? editingProduct.nombre : newProduct.nombre}
                            onChange={handleInputChange}
                        />
                        <input
                            type="number"
                            name="cantidad"
                            placeholder="Cantidad"
                            value={editingProduct ? editingProduct.cantidad : newProduct.cantidad}
                            onChange={handleInputChange}
                        />
                        <input
                            type="number"
                            name="precio"
                            placeholder="Precio por unidad"
                            value={editingProduct ? editingProduct.precio : newProduct.precio}
                            onChange={handleInputChange}
                        />
                        {editingProduct ? (
                            <button onClick={handleUpdateProduct} className="agregar-producto">Actualizar Producto</button>
                        ) : (
                            <button onClick={handleAddProduct} className="agregar-producto">Agregar Producto</button>
                        )}
                    </div>
                </div>
            </div>
            <div className="busqueda">
                <input
                    type="text"
                    placeholder="Buscar producto"
                    value={busqueda}
                    onChange={(e) => setBusqueda(e.target.value)}
                />
                <button className="buscar-producto">Buscar</button>
            </div>
            <table className="lista-productos">
                <thead>
                    <tr>
                        <th>Cantidad</th>
                        <th>Producto</th>
                        <th>Valor U.</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {productosFiltrados.map((product) => (
                        <tr key={product.id}>
                            <td>{product.cantidad}</td>
                            <td>{product.nombre}</td>
                            <td>${product.precio}</td>
                            <td>
                                <button className="editar-producto" onClick={() => handleEditProduct(product)}>Editar</button>
                                <button onClick={() => handleDeleteProduct(product.id)}>Eliminar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductList;

