const dev = {
    apiUrl: 'http://localhost:5001/api',
  };
  
  const prod = {
    apiUrl: 'https://agdistri.online/api',
  };
  
  const config = process.env.NODE_ENV === 'development' ? dev : prod;
  
  export default config;
  