import React, { useState } from 'react';
import axios from 'axios';
import config from '../config'; // Importa la configuración
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/DailyReport.css';
import flechaAtras from '../images/flecha-atras.png';

// Configuración de la instancia de axios con la URL base desde config
const api = axios.create({
    baseURL: config.apiUrl,
});

const DailyReport = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [noDataMessage, setNoDataMessage] = useState('');

    const fetchReportData = async (date) => {
        try {
            const response = await api.get(`/reports?date=${date}`); // Usa la instancia configurada
            setReportData(response.data);
            setNoDataMessage(''); // Limpia el mensaje si hay datos
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setReportData([]); // Limpia los datos del reporte
                setNoDataMessage('No hay ventas registradas en esta fecha.'); // Muestra el mensaje de no hay datos
            } else {
                console.error('Error fetching report data:', error);
                setReportData([]);
                setNoDataMessage('Ocurrió un error al obtener los datos del reporte.');
            }
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (date) {
            const formattedDate = date.toISOString().split('T')[0];
            fetchReportData(formattedDate);
        } else {
            setReportData([]);
            setNoDataMessage('');
        }
    };


    return (
        <div className="container">
            <div className="flecha10">
                <a href="/">
                    <img src={flechaAtras} alt="Ir a la página principal" />
                </a>
            </div>
            <h1 className="heading">Informe Diario de Ventas</h1>
            <div className="calendar">
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Seleccione una fecha"
                    customInput={<CustomInput />}
                />
            </div>
            {reportData.length > 0 ? (
                <div className="report">
                    <h2>Ventas del {selectedDate.toLocaleDateString()}</h2>
                    <table className="report-table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad Total</th>
                                <th>Precio Unitario</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.map((item, index) => (
                                <tr key={index}>
                                    <td data-label="Producto">{item.nombre_producto}</td>
                                    <td data-label="Cantidad Total">{item.total_vendido}</td>
                                    <td data-label="Precio Unitario">{item.precio_unitario.toFixed(2)}</td>
                                    <td data-label="Subtotal">{item.subtotal.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3">Total</td>
                                <td>
                                    {reportData.reduce((total, item) => total + item.subtotal, 0).toFixed(2)}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            ) : (
                selectedDate && <p>{noDataMessage}</p>
            )}
        </div>
    );
};

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input" onClick={onClick} ref={ref}>
        {value || 'Seleccione una fecha'}
    </button>
));

export default DailyReport;


















